.container {
  display: flex;
  align-items: center;
  justify-content: center;
  .placeholder {
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d3f0f9;
    border: 1px solid #b4d3dd;
    text-align: center;
  }
}
