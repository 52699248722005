.buttonAsLink {
    padding: 0;
    margin: 0;
    border: none;
    text-decoration: underline;
    &,
    &:hover {
        background: none;
    }
}
