.relatedPolicies {
  margin-top: 3rem;
  margin-bottom: 3rem;

  > h2 {
    margin-top: 1em;
    margin-bottom: 0.5em;

    font-family: Rawline !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 26px !important;
    line-height: 30px !important;
    color: #303434 !important;
  }

  .others {
    display: flex;
    // justify-content: space-between;
  }
}
