.introSection {
  border-top: 1px solid #c4c4c4;
  // border-bottom: 1px solid #c4c4c4;
  padding-bottom: 3rem;
  h2 {
    margin-top: 25px;
  }
  h3 {
    font-family: Rawline;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #303434;
  }
}

.policyEnvironment {
  width: 100%;
  margin-bottom: 3rem;
}

.barChartAndParagraph {
  display: grid;
  grid-template-columns: minmax(0, 1.5fr) 0.75fr;
  grid-gap: 30px;
}
