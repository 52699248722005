@import "src/assets/styles/vars.module.scss";
.bodySection {
    height: 100%;
    display: flex;
    flex-flow: column;
    .title,
    .actions {
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.1875rem;
    }
    .title {
        color: $idea-gray-2;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    .content {
        margin-bottom: 20px;
    }
    .actions {
        color: $idea-primary-dark-blue;
        margin-top: auto;
        display: flex;
        flex-flow: column;
        line-height: 1.5;
        a {
            text-decoration: underline;
        }
        [data-disabled] {
            a,
            button,
            span {
                color: $gray-2;
                text-decoration: none;
                cursor: default;
            }
        }
    }
}
