@import "../../../assets/styles/vars.module.scss";

.mapboxMap {
  // override mapbox popup defaults
  width: auto;
  // min-height: 50vh;
}
.navAndAttribControl {
  position: absolute;
  top: 105px;
  left: 10px;
  padding: 0;
  .attributionControl {
    position: relative;
    top: 5px;
    &.empty + .navigationControl {
      top: 10px;
    }
  }
  .navigationControl {
    position: relative;
    top: 50px;
  }
}
// MAPBOX LEGEND
.legend {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  border-bottom: none;
  z-index: 0;
  pointer-events: none;
  position: relative;
  > * {
    pointer-events: all;
  }

  button {
    border-radius: 4px 4px 0 0;
    outline: none;
    border: 1px solid #b3b3b3;
    border-bottom: none;
    position: relative;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 5px;
    i {
      font-size: 0.9rem;
      transform: rotate(270deg);
      top: 2px;
      position: relative;
      color: #a4a4a4;
      margin-left: 5px;
      transition: 0.5s transform;
      &.flipped {
        transform: rotate(270deg) scaleX(-1);
        transition: 0.5s transform;
        top: 0;
      }
    }
  }

  .entries {
    max-width: calc(100vw - 250px);
    display: flex;
    background-color: rgba(255, 255, 255, 0.85);
    > div {
      background-color: transparent;
    }
    padding: 5px 10px;
    border: 1px solid #b3b3b3;
    border-bottom: none;

    top: 100%;
    transition: all 0.5s;
    position: relative;

    @include borderItemsHoriz(20px);
    > div:not(:first-child) {
      padding-left: 0;
      margin-left: 0;
    }
  }
  > button {
    transition: 0.5s top;
    top: 100%;
  }

  transition: 0.5s bottom;
  bottom: 34px; // TODO dynamically
  margin: 0 auto;
  &.show {
    transition: 0.5s bottom;
    bottom: 0px;
    > button {
      top: 0%;
    }
    .entries {
      transition: all 0.5s;
      top: 0%;
    }
  }
}

// override default mapbox styles
div[class^="mapboxgl-popup "] {
  z-index: 1;
  div[class*="mapboxgl-popup-content"] {
    padding: 0;
  }
}
[class*="mapboxgl-ctrl-attrib"] a {
  font-size: 0.6rem;
  width: max-content;
}

[class*="overlays"] {
  display: flex;
  position: absolute;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
  pointer-events: none;
  * {
    pointer-events: all;
  }
}

// override default mapbox controls
[class*="mapboxgl-ctrl"] {
  background: none !important;
  display: flex;
  flex-flow: column;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;

  [class*="mapboxgl-ctrl-logo"] {
    display: none;
  }

  &:focus {
    outline: none !important;
  }

  [class*="mapboxgl-ctrl-compass"] {
    display: none;
  }

  $icon-size: 40px;
  [class*="mapboxgl-ctrl-icon"] {
    margin: 0;
    background-color: white !important;
    border-radius: 50% !important;
    margin-bottom: 5px;
    height: $icon-size;
    width: $icon-size;
    opacity: 1;

    $button-border: 2px solid #aaa;
    &[class*="mapboxgl-ctrl-zoom-in"] {
      border: $button-border;

      &,
      [class*="mapboxgl-ctrl-icon"] {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:%23999;' d='M 10 6 C 9.446 6 9 6.4459904 9 7 L 9 9 L 7 9 C 6.446 9 6 9.446 6 10 C 6 10.554 6.446 11 7 11 L 9 11 L 9 13 C 9 13.55401 9.446 14 10 14 C 10.554 14 11 13.55401 11 13 L 11 11 L 13 11 C 13.554 11 14 10.554 14 10 C 14 9.446 13.554 9 13 9 L 11 9 L 11 7 C 11 6.4459904 10.554 6 10 6 z'/%3E %3C/svg%3E") !important;
      }
    }
    &[class*="mapboxgl-ctrl-zoom-out"] {
      border: $button-border;
      &,
      [class*="mapboxgl-ctrl-icon"] {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:%23999;' d='m 7,9 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 6,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 z'/%3E %3C/svg%3E") !important;
      }
    }

    &:hover {
      background-color: darken(white, 5%) !important;
    }

    &:active {
      background-color: darken(white, 15%) !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
  [class*="mapboxgl-ctrl-attrib-button"] {
    display: none;
  }
}

// responsiveness
@media #{$phone} {
  .legend .entries {
    min-width: max-content;
    flex-flow: column;
    > div {
      border-right: none !important;
      margin-right: 0 !important;
      padding-right: 0 !important;
    }
  }
}
