.removeFilterButton {
  font-size: 0.9rem;
  border: none;
  background: none;
  // background-color: #4682b4;
  background-color: #3274aa;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  margin: 0.125em;
  color: white;
  text-align: left;
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: calc(100% - 0.4em) 0.425em;
  padding-right: 2em;
  border: 1px solid #00000022;
}

.lightIcon {
  // background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 105 105' style='enable-background:new 0 0 105 105;' xml:space='preserve'%3E%3Cpath style='fill:%23FFFFFF88;' d='M52.5,0C23.51,0,0,23.51,0,52.5C0,81.49,23.51,105,52.5,105c28.99,0,52.5-23.51,52.5-52.5 C105,23.51,81.49,0,52.5,0z M74.42,82.91L52.5,60.99L30.93,82.55l-8.49-8.49L44.01,52.5L22.09,30.58l8.49-8.49L52.5,44.01 l21.57-21.57l8.49,8.49L60.99,52.5l21.92,21.92L74.42,82.91z'/%3E%3C/svg%3E%0A");
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8334 5.3415L14.6584 4.1665L10 8.82484L5.34169 4.1665L4.16669 5.3415L8.82502 9.99984L4.16669 14.6582L5.34169 15.8332L10 11.1748L14.6584 15.8332L15.8334 14.6582L11.175 9.99984L15.8334 5.3415Z' fill='%23303434'/%3E%3C/svg%3E%0A");
}

.darkIcon {
  // background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 105 105' style='enable-background:new 0 0 105 105;' xml:space='preserve'%3E%3Cpath style='fill:%2300000088;' d='M52.5,0C23.51,0,0,23.51,0,52.5C0,81.49,23.51,105,52.5,105c28.99,0,52.5-23.51,52.5-52.5 C105,23.51,81.49,0,52.5,0z M74.42,82.91L52.5,60.99L30.93,82.55l-8.49-8.49L44.01,52.5L22.09,30.58l8.49-8.49L52.5,44.01 l21.57-21.57l8.49,8.49L60.99,52.5l21.92,21.92L74.42,82.91z'/%3E%3C/svg%3E%0A");
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8334 5.3415L14.6584 4.1665L10 8.82484L5.34169 4.1665L4.16669 5.3415L8.82502 9.99984L4.16669 14.6582L5.34169 15.8332L10 11.1748L14.6584 15.8332L15.8334 14.6582L11.175 9.99984L15.8334 5.3415Z' fill='%23303434'/%3E%3C/svg%3E%0A");
}
