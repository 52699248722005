h2.h2alt {
  font-family: Rawline !important; // todo fix !important
  font-style: normal !important; // todo fix !important
  font-weight: bold !important; // todo fix !important
  font-size: 26px !important; // todo fix !important
  line-height: 30px !important; // todo fix !important
  color: #303434 !important; // todo fix !important
  margin-bottom: 0.5em;
}

h3.orangeDate {
  font-family: Open Sans !important; // todo fix !important
  font-style: normal !important; // todo fix !important
  font-weight: 600 !important; // todo fix !important
  font-size: 16px !important; // todo fix !important
  color: #e55e37 !important; // todo fix !important
  margin-top: 0 !important; // todo fix !important
  margin-bottom: 1.5rem !important; // todo fix !important
  text-transform: none !important; // todo fix !important
}
