@import "../../../assets/styles/vars.module.scss";
.navWrapper {
  font-family: "Rawline", serif;
  border-bottom: 1px solid $amp-green;
  width: 100%;
  background: white;
  z-index: 2;

  .nav {
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    .menu {
      .links {
        display: flex;
        font-size: 1rem;
        > :not(:first-child) {
          margin-left: 2rem;
        }
        > div {
          position: relative;
        }
      }
      .hamburger {
        display: none;
      }
    }
    a {
      color: $dark-navy-blue;
    }
    .active {
      font-weight: 600;
    }
    > a:first-child {
      margin-right: auto;
      display: flex;
      align-items: center;
    }
    // img {
    // height: 50px;
    // }
  }
  @media not #{$phone} {
    &.wide {
      position: fixed;

      div {
        max-width: unset;
      }
      &[data-page="policymaps"] {
        + div {
          height: calc(100% - 116px);
          top: 116px;
        }
      }
      &[data-page="model"] {
        border-bottom: 2px solid $amp-green;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
      }
    }
  }
  &:not(.wide) {
    position: fixed;
    top: 0;
    + div {
      margin-top: 116px;
    }
  }
}
@import "./plugins.module.scss";
@import "./responsive.module.scss";

.hoverTarget {
  margin: -15px;
  padding: 15px;
  &.withSubmenu {
    padding-right: 0;
  }
}

.navSubmenu {
  border: 1px solid #647f7966;
  border-radius: 5px;
  border-top-right-radius: 0;
  background-color: white;
  margin-top: 5px;

  // flow
  display: flex;
  flex-flow: column;
  white-space: nowrap;

  > a,
  > div {
    border-radius: 0;
    &:first-child {
      border-top-left-radius: 5px;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
  }

  .active {
    background-color: $light-gray-3;

    > a,
    > div,
    span {
      color: $selected-blue;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  > a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  // submenu item
  > a,
  > div {
    padding: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 0.9rem;
  }
  > a {
    cursor: pointer;
    &:hover {
      background-color: $light-gray-2;
      color: $selected-blue;
    }
  }
}
.linkWithTooltip {
  display: flex;
  align-items: center;
}
