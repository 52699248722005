.introSection {
  flex-basis: 75%;
  margin-bottom: 2rem;

  &:not(:last-child) {
    padding-right: 2rem;
  }

  h1 {
    margin-top: 2rem;
    color: #29334b;
    font-family: "rawline";
    text-transform: none;
    margin-bottom: 1.5rem;
    line-height: 1.3;
  }

  p {
    margin-top: 1rem;
    font-size: 16px;
  }

  .quickFacts {
    // display: flex;
    // flex-wrap: wrap;
    // padding-top: 1rem;
    padding-left: 0.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    div {
      display: flex;
      // margin: 1rem;
      margin-top: 1rem;
      margin-left: 0;
      // padding: 1rem;
      // border: 1px solid #d2e4f1;
      border-radius: 3px;
      // background-color: #ecf3f8;
      // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0625);
      text-align: center;
      margin-right: 3rem;
      font-size: 1.3rem;
      font-weight: 600;

      strong {
        font-weight: 900;
      }

      > img {
        width: 1em;
        margin-right: 0.5em;
      }
    }
    .newCases {
      img {
        width: 1.5em;
      }
    }

    .caseloadChange {
      align-items: baseline;

      .change {
        position: relative;
        top: -0.0625em;
        font-size: 0.8em;
        padding: 2.5px 5px;
        border-radius: 3px;
        color: white;

        .arrow {
          position: relative;
          top: -0.025em;
          font-size: 0.65em;
        }
      }
    }
  }

  .introParagraph {
    margin-bottom: 2rem;
  }
}
