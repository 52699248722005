@import "../../../assets/styles/vars.module.scss";

.style {
  display: flex;
  flex-flow: column;
  width: max-content;
  @include spaceItemsVert(10px);
  z-index: 1000;
  cursor: default;
  .name {
    max-width: 550px;
  }
  .content {
    display: flex;
    align-items: baseline;
    @include spaceItemsHoriz(30px);

    .entry {
      display: flex;
      flex-flow: column;
      align-items: center;
      $height: 20px;
      .symbols {
        display: flex;
        border: 1px solid #ccc;
        & + .labels {
          display: flex;
          justify-content: space-between;
        }
      }

      .circle {
        height: $height;
        width: $height;
        border-radius: 9999px;
      }
      .gradientBar {
        height: $height;
        min-width: 200px;
        max-width: 100%;
      }
      .shapeSeries {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &,
        & + .labels {
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          width: 150px;
        }
      }
      .quantized {
        display: flex;
        @include spaceItemsHoriz(2px);
        flex-flow: row wrap;

        margin-bottom: -2px;
        .rectGroup {
          display: flex;
          text-align: center;
          flex-flow: column;
          position: relative;
          align-items: center;
          font-size: 0.9em;
          // max-width: 90px;
          .rect {
            width: 3 * $height;
            margin-bottom: 2px;
            &.labelsInside {
              max-width: 90px;
              width: auto;
              height: 100%;
              padding: 5px 10px;
              display: flex;
              align-items: center;
              text-align: center;
              line-height: 1.2;
              .label {
                margin: 0 auto;
              }
            }
          }
        }

        &.grid {
          display: grid;
          .rectGroup {
            .rect {
              width: 100%;
            }
          }
        }
      }
      .rect {
        height: $height;
        width: $height * 2;
        &.skinny {
          width: $height;
        }
        &.hatched {
          background: repeating-linear-gradient(-45deg, #bfbfbf, #bfbfbf, 1px, #fff 1px, #fff 6px);
          border: 1px solid #bfbfbf;
        }
      }
    }
  }
  &.mapboxLegend {
    border: none;
  }
}

// responsiveness
@media #{$phone} {
  .quantized {
    flex-flow: column !important;
  }
}

@import "./plugins.module.scss";
