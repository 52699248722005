@import "../../../assets/styles/vars.module.scss";
.map {
  height: 100%;
  width: 100%;
  position: relative;
  // border-top: 1px solid $idea-black;
  > :last-child {
    width: 100% !important;
    height: 100% !important;
  }

  h1 {
    color: black;
    font-size: 1.7rem;
    font-family: "rawline", serif;
    font-weight: 600;
    text-transform: none;
    margin-right: 10px;
  }
  .mapOptionsTitle {
    font-size: 1.4rem;
    font-family: "rawline", serif;
    font-weight: 600;
  }
  .drawer {
    position: relative;
    .divider {
      border-left: 1px solid #b3b3b3;
      margin: 0 20px;
    }
  }

  .labelContainer {
    background-color: #7e99b6;
    margin: auto 0;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    padding: 20px;
    button.toggle {
      background: none;
      border: none;
      padding: 0;
      z-index: 0;
      i {
        font-size: 3rem;
        position: relative;
        vertical-align: middle;
        line-height: 0;
      }
    }
  }
  .subToggle {
    > [role="label"] {
      margin: 0;
    }
  }
  .circleToggle {
    input,
    select {
      margin-left: 10px;
    }
    select {
      width: max-content;
    }
    > div > label {
      font-weight: unset;
    }
  }

  .rightControls {
    div > label {
      font-weight: normal;
    }
    select {
      background: linear-gradient(to bottom, white, #e4e4e4);
      border-radius: 3px;
      border: 2px solid #d3d3d3;
      padding: 0.25em 0.5em;
      margin-top: 0.5em;
      display: inline-block;
    }
  }

  // indented filters
  .indented {
    @include indented;
  }
}
