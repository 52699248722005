.filterSection {
  button {
    &:focus {
      outline: revert;

      &:not(:focus-visible) {
        outline: none;
      }
    }
    &:focus-visble {
      outline: revert;
    }
  }

  .filterBar {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
}
