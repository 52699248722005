.sectionContainer {
  display: flex;
  height: 27rem;
}

.header2 {
  font-family: Rawline;
  font-style: normal;
  font-weight: bold;
  color: #303434;
}
.asOfDate {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  color: #e55e37;
}
.chartContainer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  > h3 {
    margin-left: 3rem;
  }
  h3 {
    margin-bottom: 1em;
  }
}

.labels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  // to keep it lined up
  // because of the legend
  // padding-bottom: 30px;

  h3 {
    margin-bottom: 1em;
  }

  > span {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 1.2;
    font-size: 18px;
  }
}

.headerContainer {
  display: flex;
  margin-bottom: 1rem;

  .labelsHeader {
    margin-left: 9.5%;
  }
  .chartHeader {
    margin-left: 7%;
  }
}

.legendContainer {
  .legend {
    display: flex;
    height: 20px;
    margin-top: 10px;
    margin-left: 28%;
    font-size: 15px;

    .entry {
      display: flex;
      margin-left: 50px;

      .total,
      .active {
        width: 1.25em;
        height: 1.25em;
        flex-shrink: 0;
        margin-right: 0.5em;
      }
    }
  }
}
