@import "src/assets/styles/vars.module.scss";
.style {
  $padding: 1.25em;
  width: 100%;
  > div {
    background-color: white;
  }

  // section dividers
  hr {
    border-top: $border;
  }

  // drawer text and open/close toggle
  .header {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    font-size: 1.625rem;
    font-weight: 700;
    font-family: "rawline", serif;
    padding: 20px;
    line-height: 0;
    // min-height: 88px; // TODO elegantly
    @media #{$phone} {
      padding: 15px;
      button {
        position: absolute !important;
        right: 3px;
      }
    }
    &:not(.noCollapse) {
      &,
      * {
        cursor: pointer;
      }
    }

    // e.g., "Download all/filtered data"
    .action {
      .primary {
        font-family: "rawline", serif;
        background-color: $blue;
        color: white;
      }
    }
  }
  .content {
    padding: $padding;
    font-size: 1rem;
    background-color: $light-gray-2;
    // > {
    //   @include spaceItemsVert(1.25rem);
    // }
    // @include spaceItemsVert(1.75rem);

    min-height: 190px;

    &.hidden {
      // visibility: hidden;
      display: none;
      height: 0;
      padding: 0 $padding;
    }
  }
}
