.policyPage {
  font-size: 16px;

  p {
    color: #303434;
    font-size: 1.1rem;
    line-height: 1.5;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    strong {
      font-weight: 600;
    }
  }

  header {
    margin-top: 1.3rem;
    h1 {
      font-weight: bold;
      font-size: 32px;
      color: #303434;
    }
  }

  h2 {
    font-size: 22px;
    line-height: 30px;

    // margin-bottom: 1.5rem;

    font-family: Rawline;
    font-style: normal;
    font-weight: 600;

    color: #303434;
  }

  h3 {
    margin-top: 30px;
    // margin-top: 10px;
    margin-bottom: 0.25em;
    font-family: "rawline";
    // font-size: 16px;
    font-weight: 400;
    font-size: 0.9rem;
    color: #5f6678;
    text-transform: uppercase;
  }

  .mainSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.8rem;
    border: 1px solid #5c5d60;
    border-radius: 5px;
    padding: 30px;

    .section {
      display: flex;
      column-gap: 30px;
      // flex-direction: column;
      margin-bottom: 30px;
      padding-bottom: 30px;

      &:not(:last-of-type) {
        border-bottom: 1px solid #c4c4c4;
      }
    }

    .left {
      flex-basis: 65%;
      margin-right: 3%;
      // border-top: 1px solid #c4c4c4;
    }

    .right {
      margin-top: 30px;
      flex-basis: 20%;
    }

    .wide {
      flex-basis: 100%;
      // border-top: 1px solid #c4c4c4;
    }
  }
}

// simple rows and columns system
.headerInfoRow,
.row {
  display: flex;
  flex-direction: row;
  // margin-bottom: 1em;

  .wideCol {
    flex-basis: 100%;
  }

  .wideCol:not(:last-of-type),
  .col:not(:last-of-type) {
    margin-right: 8em;
  }

  .leftCol {
    flex-basis: 70%;
    margin-right: 4rem;
  }
  .rightCol {
    flex-basis: 30%;
  }
}

.headerInfoRow {
  .col:not(:last-of-type) {
    margin-right: 0em;
  }

  column-gap: 143px;

  // justify-content: space-between;
}

.courtChallengeIcon {
  display: flex;
  align-items: center;

  img {
    margin-top: 25px;
    width: 40px;
    height: 40px;
    margin-right: 1rem;
  }
}
