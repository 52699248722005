.othersInDocument {
  margin-top: 4rem;

  .lightGreyH2 {
    color: #828282;
  }

  .seeMoreText {
    margin-top: 40px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #303434;
  }

  .others {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    > a {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.policyLink {
  display: flex;
  align-items: center;
  margin-top: 20px;

  &:last-of-type {
    margin-bottom: 10px;
  }

  &:first-of-type {
    margin-top: 30px;
  }
}

.seeMore {
  background: none;
  font-style: italic;
  border: none;
  color: rgba(48, 52, 52, 1);
  margin-top: 1em;
  padding-left: 0;

  &:hover {
    text-decoration: underline;
  }
}
