.caret {
    margin-left: 1.25rem;
    transform: rotate(90deg) scaleX(-1) scaleY(1.5);
    transition: 0.25s transform ease !important;
    font-size: 1em;
    justify-self: flex-end;

    &.up {
        transform: rotate(90deg) scaleX(1) scaleY(1.5);
    }
}
