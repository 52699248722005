.miniMapHolder {
  min-width: 355px;
  // width: 300px;
  // padding-left: 10%;
  // margin-top: 5%;
  // padding: 30px;
  // padding-bottom: 15px;
  text-align: left;
  // border: 1px solid #dbdbdb;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  figcaption {
    margin-bottom: 10px;
  }

  h4 {
    padding-top: 0;
    margin-top: 0;
    font-style: SemiBold;
    font-size: 20px;
    color: #023f88;
  }

  .mapHolder {
    width: 50%;
  }
}
