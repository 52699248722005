.button {
  background: none;
  border: none;
  color: #023f88;
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  &:hover {
    text-decoration: underline;
  }
}

.policyModal {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  min-height: 80vh;

  > h2 {
    font-family: Rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: #303434;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  > h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #e55e37;
    margin-bottom: 2.5em;
  }
}
