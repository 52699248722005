@import "../../../assets/styles/vars.module.scss";

.footer {
  height: 90px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $idea-black;

  @media not #{$phone} {
    &[data-page="policymaps"] {
      display: none;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1300px;
    padding: 0px 20px;
    margin: 0 auto;
    width: 100%;
    .links {
      display: flex;
      flex-direction: row;
      max-height: 50px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 40px;
        &:not(:last-child) {
          border-right: 1px solid $idea-black;
        }
        &:first-child {
          padding-left: 0px;
        }
        &:nth-child(2) {
          //bump the height of GU logo slightly
          img {
            height: 50px;
          }
        }
        &.talusLogo {
          position: relative;
          .builtBy {
            font-family: "Raleway", sans-serif;
            font-size: 9px;
            font-weight: 600;
            color: #404041;
            position: absolute;
            left: 40px;
            top: 0px;
            @media (max-width: 1275px) {
              left: 25%;
              top: 0px;
            }
            @media (max-width: 800px) {
              display: none;
            }
          }
        }
      }
      img {
        height: 40px;
      }
    }
    .dataAsOf {
      font-style: italic;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      color: $gray-2;
    }
  }
  @media not #{$phone} {
    &.wide .content {
      max-width: unset;
    }
  }
  @media (max-width: 1275px) {
    height: unset;
    .content {
      flex-direction: column-reverse;
      .links {
        margin-bottom: 20px;
        width: 100%;
        justify-content: space-evenly;
        a {
          width: 100%;
          &:first-child {
            padding-left: 40px;
          }
        }
      }
      .dataAsOf {
        margin: 20px 0px;
      }
    }
  }
  @media (max-width: 800px) {
    .content {
      flex-direction: column-reverse;
      .links {
        flex-direction: column;
        margin-bottom: 40px;
        margin-top: 20px;
        max-height: unset;
        a {
          &:not(:last-child) {
            margin-bottom: 40px;
            border-right-width: 0px;
          }
          img {
            height: 60px;
          }
        }
      }
      .dataAsOf {
        margin: 20px 0px;
      }
    }
  }
}
