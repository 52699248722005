@import "./assets/styles/vars.module.scss";
@import "../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// @import "./assets/styles/fonts.css";
@import "./assets/styles/global.scss";
@import "./assets/styles/react-select-search.scss";

html,
body,
#root {
  font-size: 16px;
  width: 100%;
  height: 100%;
}
