@import "../../../assets/styles/vars.module.scss";
.style {
  width: 100%;
  .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    background-color: white;
    background-color: #c4cacf;
    color: $dark-blue;
    cursor: pointer;
    @include spaceItemsHoriz(20px);
    div,
    label {
      margin-bottom: 0;
    }
  }
  &.float {
    position: absolute;
    top: 0;
    z-index: 3;
    .content {
      background-color: rgba(255, 255, 255, 0.95);
      position: absolute;
      width: 100%;
      > div:first-child {
        margin-right: 50px;
      }
    }
  }
  &.open {
    .content {
      display: flex;
      border-bottom: 1px solid #b3b3b3;
    }
  }
  .content {
    padding: 20px;
  }
  .closer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    z-index: 2;
  }
  &.hidden {
    display: none;
    height: 0;
    padding: 0 20px;
  }
  &:not(.open) {
    .content {
      display: none;
    }
    i {
      transform: rotate(180deg);
    }
  }
}
