@import "src/assets/styles/vars.module.scss";
.mapDrape {
    position: absolute;
    background-color: rgba($color: white, $alpha: 0.5);
    z-index: 1;
    pointer-events: none;
    @include slidingFontSize();

    .mapBanner {
        display: flex;
        flex-flow: column;
        padding: 1em;
        pointer-events: none;
        max-width: 50vw;

        .title {
            font-family: "rawline", serif;
            font-weight: 600;
            font-size: 1.75em;
            pointer-events: all;
        }

        .dates {
            pointer-events: all;
            width: max-content;
            font-family: "rawline", serif;
            display: flex;
            flex-flow: column;
            .primary {
                font-size: 1.1em;
                font-weight: bold;
                color: $orange;
            }
            .secondary {
                display: flex;
                font-weight: normal;
                font-style: italic;
                font-size: 0.8em;
            }
        }
    }
}
