@import "../../../assets/styles/vars.module.scss";
.wrapper {
  position: relative;
  .control {
    position: relative;
    cursor: pointer;
  }
  .content {
    display: none;
    position: absolute;
    &.show {
      display: flex;
    }
  }
}
