.searchControl {
  margin: 0;
  // margin-top: 0.75rem;
  // margin-left: auto;
  position: relative;
  // margin-top: 2.5em;

  input {
    padding: 4px 8px;
    min-width: 18em;
    border: 1px solid #aaa;
    border-radius: 5px;

    &::placeholder {
      font-weight: 500;
    }
  }

  button {
    position: absolute;
    top: calc(50% - 7.5px);
    right: 10px;
    border: none;
    background: none;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 105 105' style='enable-background:new 0 0 105 105;' xml:space='preserve'%3E%3Cpath style='fill:%2300000088;' d='M52.5,0C23.51,0,0,23.51,0,52.5C0,81.49,23.51,105,52.5,105c28.99,0,52.5-23.51,52.5-52.5 C105,23.51,81.49,0,52.5,0z M74.42,82.91L52.5,60.99L30.93,82.55l-8.49-8.49L44.01,52.5L22.09,30.58l8.49-8.49L52.5,44.01 l21.57-21.57l8.49,8.49L60.99,52.5l21.92,21.92L74.42,82.91z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    height: 14px;
    width: 14px;
  }
}
