@import "../../../assets/styles/vars.module.scss";

.filter {
  display: flex;
  flex-flow: column;
  &.alignBottom {
    justify-content: flex-end;
  }
  &:not(:last-child) .filterButton {
    border-right: $border;
  }
  .label {
    color: $idea-black;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: uppercase;
    line-height: 1;
  }
  &.disabled {
    .label {
      @include disabled();
    }
    .input {
      .filterButton {
        border-color: $idea-gray-2;
      }
    }
  }
  .input {
    .filterButton {
      display: flex;

      @include dropdownAndSearchStyle();

      cursor: pointer;

      // states
      span {
        white-space: nowrap;
      }
      &.selected span.field {
        font-weight: bold;
      }
      span.selections {
        font-weight: bold;
      }

      // caret icon
      i,
      img {
        vertical-align: middle;
        margin-left: auto;
        width: 15px;
        height: 24px;
      }
      &:hover,
      &.shown {
        background-color: darken(white, 10%);
        transition: background-color 0.125s;
      }

      &:active {
        background-color: darken(white, 20%);
        @include boxShadow();
      }
      &.disabled {
        @include disabled();
      }
    }
    .filterMenu {
      position: absolute;
      transition: opacity 0.125s;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
      background-color: white;
      &.shown {
        opacity: 1;
        pointer-events: all;
        @include boxShadow();
      }

      > div {
        // height: unset !important;
      }
      max-width: 50vw;

      &:not(.dateRange) {
        min-width: 300px;
      }
      // height: unset !important;
      // height: min-content !important;

      .filterMenuWrapper {
        &.disabled * {
          // background-color: red !important;
        }
      }

      &,
      div[class^="ReactVirtualized__Grid "] {
        // height: max-content !important;

        div[class="ReactVirtualized__Grid__innerScrollContainer"] {
          // overflow-y: scroll !important;
          // max-height: 200px !important;
        }
      }

      div[class^="kn-item__item__"] {
        & + div {
          // height: max-content !important;
          // position: unset !important;
        }
      }

      div[class^="kn-column__column"] {
        // height: max-content !important;
      }
    }
  }

  .hideRangeSelection {
    width: auto;
    button[class*="rdrDayToday"] {
      span::after {
        background: #3d91ff !important;
      }
    }
    button {
      &:not([class*="rdrDayPassive"]) {
        // &:not([class*="rdrDayPassive"]):not([class*="rdrDayToday"]) {
        [class*="rdrInRange"] {
          &,
          * {
            background: transparent;
            + span > span {
              color: #333;
            }
          }
        }
      }
    }
  }
}

@media #{$phone} {
  .filter:not(:last-child) {
    margin-bottom: 10px !important;
    .filterButton {
      border-right: none;
    }
  }
}
