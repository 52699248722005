@import "../../../assets/styles/vars.module.scss";
.data {
  // header with page title and introduction text
  @include spaceItemsVert(1rem);

  .columnText {
    display: flex;
    flex-flow: column;
    p {
      max-width: 70rem;
    }
    ul {
      margin-top: 1rem;
      line-height: 2;
    }
  }
  .unspecified {
    font-style: italic;
    color: $gray;
  }

  // filter header with instructions, clear filters button, search bar
  .filtersHeader {
    display: flex;
    align-items: center;
    margin-top: unset !important;
  }

  // table styles
  .linkIcons {
    display: flex;
    flex-flow: row wrap;
    .linkIcon {
      position: relative;
      top: 5px;
      z-index: 0;
    }
    // manually set widths of certain table cols
  }
  table {
    &.policy {
      th,
      td {
        // set width of link column
        &:last-child {
          width: 88px;
        }
        // set width of Description column
        &:nth-child(3) {
          width: 25%;
        }
        // set width of Category column
        &:nth-child(4) {
          width: 20%;
        }
        @media #{$small-laptop} {
          // hide jurisdiction type
          &:nth-child(2) {
            display: none;
          }

          @media #{$phone} {
            // hide cat, subcat, targets
            &:nth-child(4) {
              display: none;
            }
            @media #{$phone-narrow} {
              // hide date and authority
              &:nth-child(6),
              &:nth-child(7) {
                display: none;
              }
              // allow more width for desc.
              &:nth-child(3) {
                width: 50% !important;
              }
            }
          }
        }
      }
    }
    &.plan {
      th,
      td {
        // set width of link column
        &:last-child {
          width: 88px;
        }
        // set width of Description column
        &:nth-child(4) {
          width: 35%;
        }
        @media #{$small-laptop} {
          // hide org. type
          &:nth-child(2) {
            display: none;
          }

          @media #{$phone-narrow} {
            // hide date and authority
            &:nth-child(5),
            &:nth-child(6) {
              display: none;
            }
            // allow more width for desc.
            &:nth-child(4) {
              width: 50% !important;
            }
          }
        }
      }
    }
    &.court_challenge {
      th,
      td {
        &:nth-child(1) {
          width: 110px;
        }
        &:nth-child(2) {
          width: 110px;
        }
        &:nth-child(3) {
          width: 300px;
        }
        &:nth-child(4) {
          width: 150px;
        }
        &:nth-child(5) {
          width: 90px;
        }
        &:nth-child(6) {
          width: 110px;
        }
        &:nth-child(7) {
          width: 100px;
        }

        i {
          position: relative;
          top: 7px;
        }
      }
    }
  }

  // drawer components
  // content top section
  .contentTop {
    display: flex;
    align-items: flex-start;
    // TODO elegantly
    > :not(:last-child) {
      margin-right: 5.6rem;
    }
    @media #{$small-laptop} {
      flex-flow: column;
      align-items: flex-start;
      > div {
        margin-bottom: 10px;
      }
    }
  }

  // custom grid layout for drawer content
  // TODO fix class
  [class*="drawer_content"] {
    display: grid;
    grid-gap: 1.25em;
    align-items: flex-start;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "top top top top"
      "sep sep sep sep"
      "filter filter filter filter"
      "selected selected selected selected";

    // second radio button bay
    [class^="radiotoggle_radioToggle"]:nth-child(2) {
      grid-area: top;
      grid-column: 2/4;
    }

    // divider
    hr {
      grid-area: sep;
      width: 100%;
      margin: 0;
    }

    // search
    [class*="search_search"] {
      grid-row: "top";
      grid-column: 4;
      margin: unset;
    }

    // filters
    [class*="filterset_filterGroup"] {
      grid-row: filter;
      @include spaceItemsVert(1.25em);
      width: 100%;
    }

    // selected filters
    [class^="FilterSetSelections_selectedFilters"] {
      grid-area: selected;
      grid-column: 1/-1;
    }
  }

  // basic responsiveness
  @media #{$small-laptop} {
    // TODO fix class
    [class*="drawer_content"] {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
    }
  }
}
