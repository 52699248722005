@import "src/assets/styles/vars.module.scss";

.title,
.body {
    padding: 0.625em;
}
.title {
    border: 1px solid $light-gray-4;
    border-width: 1px 0;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875em;
    line-height: 1.1875em;
    color: $idea-gray-2;
    text-transform: uppercase;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    white-space: nowrap;
    &:first-child {
        border-top-width: 0;
    }
    &.closed:not(:nth-last-child(2)) {
        border-bottom-width: 0;
    }
}
.body {
    overflow-y: scroll;
    padding: 0;
    .bodyChildContainer {
        padding: 0.625em;
    }
    &.closed {
        height: 0px !important;
    }
}
