@import "../../../assets/styles/vars.module.scss";

//IDEA rebrand button styles
.wrapper {
  .button {
    border: 2px solid $idea-primary-dark-blue;
    &:not(.link) {
      border: 2px solid $idea-primary-dark-blue;
    }
    border-radius: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $idea-primary-dark-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    &:hover {
      background-color: #f4f4f4;
      border: 2px solid #023f88;
    }
    &:active {
      box-shadow: 5px 5px 15px 0 #b3b3b3;
      -webkit-box-shadow: 5px 5px 15px 0 #b3b3b3;
    }
    cursor: pointer;
    &:not(.link) {
      background: linear-gradient(180deg, rgba(2, 63, 136, 0.85) 0%, #023f88 100%);
      color: white;
      &:hover {
        background: white;
        color: $idea-primary-dark-blue;
        border: 2px solid $idea-primary-dark-blue;
      }
    }
    &.secondary {
      background: white;
      color: $idea-black;
      border-color: $idea-black;
      i {
        color: $idea-black;
      }
      // color: $idea-primary-dark-blue;
      // border-color: $idea-primary-dark-blue;
      // i {
      //   color: $idea-primary-dark-blue;
      // }
      &:hover,
      &.active {
        // color: $idea-primary-dark-blue;
        // border-color: $idea-primary-dark-blue;
        background-color: #f4f4f4;
      }
    }
  }
}
