.listPoliciesPage {
  h1 {
    font-family: Rawline;
    font-style: normal;
    font-weight: bold;
    color: #303434;
    font-size: 32px;
    padding-top: 2px;
  }
  h2 {
    font-family: Rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    color: #303434;
  }
}

.pageHeader {
  margin-top: -20px;
  // padding-bottom: 1rem;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // border-bottom: 1px solid #c4c4c4;
}

.miniMapHolder {
  height: 80px;
  width: 60px;
  padding-top: 10px;
  padding-bottom: 10px;

  figcaption {
    margin-bottom: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #303434;
  }

  > a {
    margin-top: 10px;
    display: block;
    width: 100%;
    text-align: center;
  }
}

.policyList {
  // padding-right: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  border-top: 1px solid #c4c4c4;

  h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
  }

  > h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #303434;
    font-family: "rawline";
    text-transform: none;
    font-weight: bold;
    font-size: 28px;
  }
}

.policyList {
  min-height: 70vh;
}
