@import "../../../assets/styles/vars.module.scss";
.badge {
  padding: 5px;
  font-size: 1rem;
}
.distancingLevelTable {
  tr {
    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
    td {
      vertical-align: middle;
      padding-bottom: 10px;
      &:not(:first-child) {
        padding-top: 10px;
      }
    }
  }
  td:first-child {
    width: min-content;
  }
  td:nth-child(2) {
    width: 20%;
    text-align: center;
    > div {
      height: 30px;
    }
  }
  .rect {
    padding: 5px 10px;
    white-space: nowrap;
    text-align: center;
  }
}
.definitionHeader {
  display: block;
  a {
    img {
      height: 17px;
      margin-right: 5px;
    }
    font-family: "rawline", serif;
    font-size: 0.75rem;
    font-style: italic;
    color: #409385;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
    span {
      border-bottom: 1px solid #409385;
    }
    &:hover {
      $hover-color: lighten(#409385, 10%);
      color: $hover-color;
      span {
        border-bottom: 1px solid $hover-color;
      }
    }
  }
}
