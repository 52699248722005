$border-radius: 50%;

.mapOverlay {
  position: absolute;
  z-index: 1;
}

.resetButton {
  $icon-size: 40px;
  top: calc(calc(#{$icon-size} * 2) + 10px);
  position: absolute;

  > button {
    color: #333;
    font-size: 1em;
    display: block;
    outline: none;
    border: 2px solid #aaa;
    box-sizing: border-box;
    background-color: transparent;
    border-radius: $border-radius !important;
    cursor: pointer;
    opacity: 1;
    height: $icon-size;
    width: $icon-size;
    padding: 5px;
    margin: 0;

    color: #878787;
    background-color: white;

    &:hover {
      background-color: darken(white, 5%);
    }
    &:active {
      background-color: darken(white, 15%);
    }

    i {
      position: relative;
      top: 1px;
    }
  }
}
