@import "../../../assets/styles/vars.module.scss";

.selectpicker {
  font-family: "Open Sans", sans-serif;
  .label {
    margin-bottom: 5px;
    font-weight: 600;
  }

  select {
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
    max-width: 200px;
    height: 36px;
    cursor: pointer;
  }
}
