.markerDot {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  // background-color: #7fb0b4;
  margin-right: 8px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;

  .arrow {
    width: 10px;
    height: 10px;
    background-size: contain;
    transition: 250ms ease;
    background-position: 50% 30%;
    background-repeat: no-repeat;
    transform: scaleY(1);
  }
}
