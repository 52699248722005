.activeDateRange {
  display: flex;
  flex-direction: column;
  // margin-bottom: -1.445em;

  label {
    font-weight: 400;
    font-size: 0.8em;
  }
}
