@import "../../../assets/styles/vars.module.scss";

.table {
  > [role="subheader"] {
    font-size: 26px; // TODO unify
    > span {
      @include horiz();
    }
  }
  h2 {
    font-size: 20px;
    text-transform: uppercase;
    color: $cyan;
  }

  // table sorting icons
  img.sortable {
    margin-left: 5px;
    $size: 20px;
    height: $size;
    width: $size;
  }
  th {
    position: relative;
    text-align: left;
    font-family: "Open Sans", serif;
    background-color: $light-gray;
    vertical-align: bottom;
    font-size: 1.06rem;
    > div > p:first-child {
      margin-right: 20px;
      margin-bottom: 5px;
    }
    img {
      top: 0;
      right: 7px;
      margin: 0;
      position: absolute;
    }
  }
  // clear borders
  table {
    border: 2px solid $gray !important;
    td > a {
      display: flex;
      align-items: center;
      color: $selected-blue;
      &,
      * {
        text-decoration: none !important;
      }
      i {
        margin-right: 5px;
        + span {
          font-style: italic;
        }
      }
    }

    td,
    th {
      @include spaceItemsVert(10px);
      border: none !important;
      &:not(:last-child) {
        border-right: 1px solid $gray-2 !important;
      }

      @media #{$small-laptop} {
        font-size: 0.9em;
        .definition {
          display: none;
        }

        i {
          font-size: 1.25rem;
          position: relative;
        }
      }
    }
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $gray-2 !important;
      }
    }
    th {
      @include sticky();
      // top: 0;
      top: 116px;
      z-index: 1;
      @media #{$phone} {
        position: relative;
        top: unset;
      }
    }

    &,
    tbody tr td,
    thead tr th {
      vertical-align: baseline;
      > span {
        // display: flex;
        // align-items: center;
        .caret {
          margin-left: auto;
          font-size: 34px;
          transform: rotate(180deg);
          transition: transform 0.5s;
        }
      }
    }
    &.expandable {
      td:not([class="reset-expansion-style"]) {
        cursor: pointer;
      }
    }
    tr.expandedParent {
      .caret {
        transform: unset;
        transition: transform 0.5s;
      }
    }
  }
  .unspecified {
    font-style: italic;
    color: $gray;
  }

  // Bootstrap tables
  .paginationTotal {
    margin-left: 1rem;
  }
  [class="row react-bootstrap-table-pagination"] {
    display: flex;
    align-items: center;
  }
  [class^="react-bootstrap-table-pagination-list"] {
    ul {
      float: right;
    }
  }
  label[for="search-bar-0"] {
    font-weight: normal;
    margin-bottom: 20px;
    &::placeholder {
    }
    input {
      border-color: #999;
    }
  }
  .definition {
    font-style: italic;
    font-size: 1rem;
    font-weight: normal;
  }
}
