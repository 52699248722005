@import "../../../../assets/styles/vars.module.scss";
.documentation {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .downloads {
      @include spaceItemsHoriz(10px);
      display: flex;
    }
  }

  // nested ordered lists are lowercase letters
  ol > li > ol {
    list-style: lower-alpha;
  }
  section.endnotes {
    font-size: 1rem;
    > p {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      > a {
        // margin-right: 20px;
        min-width: 20px;
      }

      > p {
        margin: 0 !important;
        // margin-left: 20px !important;
      }
    }
  }

  hr {
    border-color: lightgray;
    margin-top: 60px;
  }

  [data-type*="ref"],
  [data-type*="endnote"],
  [name^="_ednref"] {
    vertical-align: super;
    font-size: 0.9rem;
    cursor: pointer;

    // sep multiple refs with commas
    + [data-type*="ref"]:before {
      content: ", ";
    }
  }

  .figureCaption,
  img + p {
    font-size: 1rem;
  }

  table,
  tr,
  td,
  th {
    border: 1px solid #333;
  }

  table {
    width: 100%;
    margin-bottom: 40px;
    td:first-child {
      max-width: 300px;
    }
    td:nth-child(2) {
      max-width: 300px;
    }
    tr:nth-child(2) {
      td,
      th {
        font-weight: bold;
      }
    }
  }

  td,
  th {
    padding: 5px;
    vertical-align: top;
  }
}
