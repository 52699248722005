@import "../../../assets/styles/vars.module.scss";
.about {
  margin: 0 auto;
  // max-width: 800px;
  font-size: 1.2rem;

  h1,
  h2,
  h3 {
    text-transform: none;
  }

  h2 {
    margin-bottom: 15px;
  }

  h3,
  h4 {
    margin-bottom: 5px;
  }

  h4 {
    font-weight: bold;
    font-size: 1.2rem;
  }
  p {
    + h1,
    + h2,
    + h3 {
      margin-top: 30px;
    }
  }
  ol,
  ul {
    + h1,
    + h2,
    + h3,
    + p {
      margin-top: 30px;
    }
  }

  ol,
  ul {
    > li {
      margin-bottom: 10px;
    }
    ol,
    ul {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .phaseCriteria {
    p {
      margin: 0 !important;
    }
    ul,
    ol {
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
  .title {
    margin: 28px 0;
    text-transform: none;
    font-size: 28px;
    color: $idea-black;
  }
  .subtitle {
    margin: 28px 0;
  }
  .tabs {
    @include spaceItemsHoriz(10px);
    position: relative;
    top: 1px;
    > a,
    > button span:first-child {
      font-size: 1.2rem;
    }
    > button img {
      height: 30px;
      width: 30px;
    }
    > button {
      padding: 5px 0 5px 10px;
    }
    z-index: 1;
    align-items: flex-start;
    display: flex;
    a button {
      font-family: "rawline", serif;

      background: none;
      background-color: #bde7ff;
      padding: 10px 30px;
      border: 1px solid #bde7ff;

      color: #808080;

      &.active {
        background-color: white;
        border-bottom-color: white;
        color: #333;
        font-weight: bold;
      }
    }
  }
  .content {
    border: 1px solid #bde7ff;
    padding: 30px 40px;
    position: relative;

    section:not(:last-child) {
      border-bottom: 3px solid #bde7ff;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    section > p {
      // max-width: 900px;
    }

    img {
      margin-bottom: 28px;
      // max-height: 80px;
    }
    a {
      + p {
        margin-top: 20px;
      }
    }
    .articles {
      margin-left: 10px;
      article {
        display: flex;
        flex-flow: row;
        // justify-content: space-between;
        align-items: flex-start;

        &:not(:last-child) {
          border-bottom: 2px solid #d5d5d5;
          margin-bottom: 20px;
          padding-bottom: 20px;
        }

        a {
          display: flex;
          text-align: left;
          justify-content: flex-start;
          margin: 0;
          > div {
            width: 270px;
          }
        }
        img {
          margin: 0;
          // height: 100%;
          // width: 100%;
          max-height: 80px;
          margin-right: auto;
        }
        p {
          // max-width: 600px;
          margin-top: 0;
          margin-left: 1rem;
        }
      }
    }
    i {
      color: gray;
    }
    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .downloads {
    display: flex;
    @include spaceItemsHoriz(10px);
    position: absolute;
    top: 0;
    right: 40px;
    a.download {
      text-decoration: none;
      button {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        @include btn-primary();
        color: white;
        i {
          color: white;
          margin-right: 10px;
          margin-left: -10px;
        }
        &:hover,
        &:active {
          i {
            color: black;
          }
        }
      }
      &.mini {
        // position: absolute;
        // right: 30px;
        // top: 0;
        font-size: 0.8rem;
        button {
          padding: 5px 10px;

          i {
            margin-right: 5px;
            margin-left: -5px;
          }
        }
      }
    }
  }
}
@import "./responsive.module.scss";
