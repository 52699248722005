@import "../../../assets/styles/vars.module.scss";
.dateSlider {
  // overriding module defaults
  [class*="rc-slider-handle"] {
    z-index: 1;
    // pointer-events: none;
  }
  [class*="rc-slider-mark"] {
    z-index: 0;
  }

  display: flex;
  flex-flow: column;
  $background-color: rgba(4, 4, 4, 0.95);

  // toggle and hide/show styling
  &.hide {
    padding: 0 !important;
    border: none !important;
    .label,
    .content {
      display: none;
    }
    button.toggle {
      top: 2px;
      left: 2px;
      bottom: unset;
      right: unset;
      border-width: 1px;
      border-radius: 4px;
      width: max-content;
      padding: 10px 5px;
      .buttonText {
        font-style: italic;
        font-size: 0.9em;
        .icon {
          font-style: normal;
        }
      }
      > div {
        top: -1px;
        left: unset;
      }
    }
  }
  button.toggle {
    white-space: nowrap;
    width: 15px;
    margin-left: auto;
    font-size: 1em;
    position: absolute;

    bottom: 0px;
    right: 0px;
    top: unset;

    margin-top: 0;
    height: 15px;
    min-width: 5px;
    padding: 0;
    line-height: 0;
    background-color: white;

    border: 1px solid #b3b3b3;
    border-width: 1px 0 0 1px;
    color: darken(#b3b3b3, 10%);
    border-radius: 4px 0 0 0;
    &:hover {
      background-color: darken(white, 5%);
    }
    &:active {
      background-color: darken(white, 15%);
    }
    > div {
      position: relative;
      top: -2px;
      left: -1px;
    }
  }

  // width: min-content;
  .label {
    width: max-content;
    font-family: "Open Sans", sans-serif;
    min-width: 315px;
    margin-bottom: 30px;
    &.bottom {
      margin-bottom: 5px;
    }
  }
  .sliderControls {
    display: flex;
    width: 100%;
    justify-content: center;

    i {
      cursor: pointer;
      font-size: 1.5em;
    }
    color: $idea-primary-dark-blue;
    .disabled {
      color: $gray;
      pointer-events: none;
    }
  }

  .content {
    width: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 25px 25px 25px;

    @include spaceItemsHoriz(10px);

    // override defaults of package
    [class*="rc-slider-dot"] {
      display: none;
    }
    [class*="mark"] {
      white-space: nowrap;
      color: #333;
      font-size: 0.9em;
    }
    $height: 12px;
    .sliderBar {
      width: 100%;
      height: $height;
      border: 1px solid #b3b3b3;
      border-radius: 4px;
      position: relative;
      $padding: 4px;
      svg {
        position: relative;
      }
      .dateLabel {
        color: white;
        white-space: nowrap;
        text-align: center;
        position: absolute;
        top: -$height - $padding * 2 - 10px;
        background-color: $orange;
        border-radius: 4px;
        font-size: 0.9em;
        font-family: "Open Sans", sans-serif;
        padding: 4px;

        &.bottom {
          top: unset;
          bottom: -$height - $padding * 2 - 5px;
        }
      }
      .grooves {
        background-color: $orange;
        line {
          stroke: white;
        }
      }
    }
  }
  .calendarPicker {
    display: flex;
    @include spaceItemsHoriz(10px);
    align-items: center;
    font-size: 0.9em;
    button {
      @include btn-secondary();
      border-radius: 4px;
      border: 1px solid #b9b9b8;

      background-color: $background-color;

      &:hover {
        background-color: darken($background-color, 5%);
      }
      &:active {
        background-color: darken($background-color, 10%);
      }
    }
    img {
      width: 14.64px;
      height: 13.36px;
    }
    &.open {
      button {
        color: white;
        background-color: #327afe !important;
        background: unset;
      }
    }
  }

  // styling for "floating" version of date slider
  &.float {
    position: absolute;
    z-index: 1;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 15px;
    border: 1px solid #b3b3b3;
    border-width: 0 1px 1px 0;
  }
}
