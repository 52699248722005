.iconHolder {
  transform: scale(0.35);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin-right: 0;
  }
}

.barPath {
  transition: 200ms ease;
}

.tooltip {
  display: flex;
  flex-direction: column;
  padding: 5px 5px 5px 0;

  h4 {
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
    margin-top: 0;
  }

  p {
    margin-top: 0.25em;
  }

  h5 {
    color: #e55e37;
    margin-top: 0;
    margin-top: 3px;
    margin-left: 2.2rem;
  }

  .policies {
    display: grid;
    grid-template-columns: 1em auto 1fr;
    row-gap: 0.5em;
    margin-left: 0.4em;

    .colorBlock {
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      // margin-left: 0.6em;
      // margin-right: 0.8em;
      position: relative;
      top: 2px;
    }

    .number {
      text-align: right;
      margin-left: 1em;
      margin-right: 0.3em;
    }
  }
}
