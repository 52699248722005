@import "../../../assets/styles/vars.module.scss";

// Credit: https://stackoverflow.com/questions/43662552ting-columns-to-wrap-in-css-grid
.filterSet {
  display: flex;
  flex-flow: wrap;
  &.vertical {
    @include spaceItemsHoriz(2.5rem);

    > .filterGroup {
      flex-flow: column;
      &.dropdowns {
        @include spaceItemsVert(1rem);
      }
    }
  }
  &:not(.vertical) {
    @include spaceItemsHoriz(5rem);
    .filterGroup {
      @include spaceItemsHoriz(50px);
    }
    &.dropdowns {
      &:not(:first-child) {
        margin-top: 1.25em;
      }
    }
  }
  .filterGroup {
    display: flex;

    &.dropdowns {
      > div {
        min-width: 250px;
      }
    }
  }
  &.disabled {
    display: none;
  }
}

@media #{$small-laptop} {
  .filterSet {
    margin: 0;
    > :not(:last-child),
    &.vertical > :not(:last-child) {
      margin-right: 0;
    }

    @include borderItemsVert(1.25em);

    .filterGroup {
      width: 100%;
      flex-flow: column;

      margin-bottom: 1.25em;
      @include spaceItemsVert(1.25em);
      > div {
        width: 100%;
      }
    }
  }
}
