@import "./vars.module.scss";

body {
  font-size: 16px;
  color: $idea-black;
}
#root {
  height: 100%;
}
body,
button {
  font-family: "Open Sans";
  font-weight: normal;
}

button {
  outline: none;
}

p {
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "Rawline", serif;
  font-weight: bold;
  margin: 0;
  color: $idea-black;
}

h1 {
  font-size: 2.4rem;
  // font-size: 3.5rem;
  @media #{$phone} {
    font-size: 2.5em;
  }
}

h2 {
  font-size: 1.9rem;
  @media #{$phone} {
    font-size: 1.5rem;
  }
}
h3 {
  text-transform: none;
  font-size: 1.25rem;
}
input[type="radio"] {
  margin: 0;
}

// cookie policy
// hide revoke menu
[class*="cc-revoke"] {
  display: none !important;
}
.cc-floating {
  &.cc-window {
    .cc-compliance {
      .cc-btn {
        &.cc-allow {
          border: 2px solid #023f88;
          background: linear-gradient(180deg, rgba(2, 63, 136, 0.85) 0%, #023f88 100%);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 600;
          &:hover {
            background: white;
            color: #023f88;
            border: 2px solid #023f88;
          }
          &:active {
            box-shadow: 5px 5px 15px 0 #b3b3b3;
            -webkit-box-shadow: 5px 5px 15px 0 #b3b3b3;
          }
        }
      }
    }
  }
}
