@import "src/assets/styles/vars.module.scss";
.panelStyles {
    @include slidingFontSize();

    &.mapPanel {
        z-index: 0;
        pointer-events: none;
        .tab,
        .body {
            pointer-events: all;
            padding: 0.625em;
            font-size: 1em;
            border: solid $light-gray-4 1px;
            background-color: rgba(255, 255, 255, 0.75);
            transition: 0.25s background-color;
        }
        &:hover {
            .tab,
            .body {
                background-color: rgba(255, 255, 255, 1);
                transition: 0.25s background-color;
            }
        }
        .tab {
            border-bottom-width: 0;
            font-family: Rawline, serif;
            font-style: normal;
            font-weight: bold;
            line-height: 1.375em;
            cursor: pointer;
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            &.fit {
                width: max-content;
            }
        }
    }
    &.panelSet {
        display: grid;
        grid-auto-flow: column;
        overflow: hidden;
        align-items: flex-end;
        justify-content: flex-end;
        position: absolute;
        z-index: 1;
        width: 100%;
        bottom: 0;
        pointer-events: none;
        transition: 1s grid-template-columns ease;
        > * {
            justify-self: flex-end;
        }
        .mapPanel {
            position: relative;
            display: grid;

            &:not(:first-of-type) {
                left: -1px;
            }
            &:last-of-type {
                left: -2px;
            }
            .body {
                overflow-y: scroll;
                display: grid;
                * {
                    transition-duration: 0.25s;
                    transition-timing-function: ease;
                    transition-property: opacity, height;
                    opacity: 1;
                }
                &.animating * {
                    transition: opacity, height 0.25s ease;
                    opacity: 0;
                }
            }
            &.maxHeight {
                grid-template-rows: auto 100%;
                align-self: flex-start;
                height: 100%;
                &.open > .tab {
                    border-top-width: 1;
                }
            }

            &:not(.maxHeight) {
                grid-template-rows: auto 7.5em;
                @media screen and (max-width: 1500px) {
                    grid-template-rows: auto auto;
                }
            }

            // &:first-of-type > .body {
            //     border-left-color: transparent;
            // }
            &:last-of-type > .body {
                border-right-width: 0;
            }
            &.drawerPanel {
                .body {
                    grid-template-rows: repeat(3, min-content minmax(0, min-content));
                    overflow-y: hidden;
                }
            }

            // transitions
            transition: bottom 0.5s ease;
            &.animating {
                transition: 0.5 bottom ease;
            }
            &:not(.animating) {
                transition: none;
            }
        }
    }
}
