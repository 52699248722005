// circular background of icon
.iconBackground {
  border-radius: 100%;
  background-color: #96d6db;
  // icon SVG image displayed in circle
  img {
    height: 100%;
  }
}

// if multiple icons, display in grid
.icons {
  display: grid;
  grid-gap: 5px;
  grid-auto-flow: row;
}
