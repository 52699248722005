@import "src/assets/styles/vars.module.scss";
.search {
  // format search bar similar to filter dropdowns
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  @include dropdownAndSearchStyle();

  input {
    border: none;
    // prevent outline on focus
    outline: none;
    @include searchPlaceholderStyle();
  }

  // close and search icons
  .icons {
    display: flex;
    align-items: center;
    margin-left: auto;

    // icon for magnifying glass
    i {
      color: $idea-black;
      font-size: 1.33rem;
      width: 18px;
    }
  }
  @media #{$small-laptop} {
    &,
    input {
      width: 100%;
    }
    justify-content: space-between;
  }
}
