.container {
  margin-top: 31px;
  font-size: 16px;
  margin-left: 3em;

  .entry {
    display: flex;
    margin-bottom: 0.5em;

    .key {
      position: relative;
      top: 3px;
      width: 1em;
      height: 1em;
      margin-right: 0.75em;
    }

    .label {
      color: #828282;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
    }
  }
}

.averageLine {
  stroke: #bdbdbd;
  fill: none;
  stroke-width: 2.5;
  stroke-linejoin: round;
  stroke-linecap: round;
}
