@import "src/assets/styles/vars.module.scss";
.searchContainer {
  width: 25rem;
  @media #{$phone} {
    width: 50vw;
    right: 0;
  }
  .searchBarAndIcons {
    display: flex;
    align-items: center;
    @include dropdownAndSearchStyle();
    .searchBar {
      width: 100%;
      outline: none;
      border: none;
      font-family: "Open Sans";
      @include searchPlaceholderStyle();
      // &::placeholder {
      // font-size: 0.9rem;
      // }
    }
    .icons {
      display: flex;
      align-items: center;
      position: absolute;
      right: 20px;
      pointer-events: none;
      // magnifying glass icon
      i {
        position: relative;
      }
    }
  }

  .results {
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    .stateResult,
    .countryResult {
      width: 100%;
      display: block;
      margin-top: 0.25rem;
      padding: 0.25rem 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: #8db3ab22;
      border-radius: 5px;

      &:hover {
        text-decoration: none;
        background-color: #9ec9c044;
      }
    }

    .stateResult {
      background-color: #809eb322;

      &:hover {
        background-color: #8aafc944;
      }
    }

    .header {
      width: 100%;
      display: block;
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      font-style: italic;
      border-bottom: 1px solid #647f7988;
      font-size: 0.9rem;
      color: #647f79;
    }
  }
}
