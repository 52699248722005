@import "../../../assets/styles/vars.module.scss";
.contact {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  .title {
    margin: 28px 0;
    text-transform: none;
    font-size: 1.66rem;
  }

  .error,
  .success,
  .warning {
    // margin: 1rem;
    margin-top: 0rem;
    margin-bottom: 1rem;
    font-weight: 500;
    padding: 1rem;
    border-radius: 5px;
  }
  .warning {
    background-color: #fffbe2;
    border: 1px solid #faecba;
  }
  form {
    .error {
      color: #862526;
      background-color: #fff2f2;
      border: 1px solid #ffd2d2;
    }

    .success {
      color: #328625;
      background-color: #f2fff3;
      border: 1px solid #d6ffd2;
      margin-top: 1rem;
    }

    .fields {
      @include borderItemsVert(20px);
    }
    .group,
    .message {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(2, 1fr);
      @include spaceItemsHoriz(20px);
      label {
        display: flex;
        flex-flow: column;
        color: #474b4d;
        // font-family: "Lato", serif;  // not installed
        font-family: "Open Sans", sans-serif;
      }
      input {
        height: 2rem;
        color: #333;
        font-weight: normal;
        max-width: 40vw;
      }
      input,
      textarea,
      select {
        margin-top: 5px;
        font-weight: normal;
        border: 1px solid #b4b4b4;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      p {
        font-weight: normal;
        font-style: italic;
        line-height: 0;
      }
      button {
        border: 2px solid #023f88;
        background: linear-gradient(
          180deg,
          rgba(2, 63, 136, 0.85) 0%,
          #023f88 100%
        );
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 30px;
        &:hover {
          background: white;
          color: #023f88;
          border: 2px solid #023f88;
        }
        &:active {
          box-shadow: 5px 5px 15px 0 #b3b3b3;
          -webkit-box-shadow: 5px 5px 15px 0 #b3b3b3;
        }
      }
    }
    .message {
      display: flex;
      flex-flow: column;
      @include spaceItemsVert(20px);
      border-color: #4d4d4d;

      select {
        width: max-content;
        min-width: 200px;
        padding: 5px;
      }
    }
  }
}
@import "./responsive.module.scss";
