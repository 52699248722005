@import "./assets/styles/vars.module.scss";
.page {
  padding: 20px;
  position: relative;
  top: 0;
  left: 0;
  max-width: $max-width;
  width: 100%;
  margin: 0 auto;
  z-index: 1;

  // TODO elegantly -- make page at least tall enough so that footer hits
  // bottom of window
  min-height: calc(100% - 108px - 116px);
  @media not #{$phone} {
    &.policymaps,
    &.model,
    &.landing {
      max-width: unset;
      padding: unset;
    }
  }
  &.landing {
    padding: unset;
  }
  // hide content if screen small
  @media #{$phone} {
    &[data-responsive]:not([data-responsive="true"]) {
      display: none;
    }
  }
}

// loading spinner styling
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  z-index: 4;
  &.on {
    opacity: 1;
    pointer-events: all;
  }
}

// popup modal
[class*="browser-modal-content"] {
  max-width: 40vw !important;
  width: auto !important;
  height: min-content;
  padding: 20px !important;
  &,
  .text {
    @include spaceItemsVert(10px);
  }
  h3 {
    font-size: 1.5em;
  }
  @media #{$phone-narrow} {
    width: 100% !important;
    max-width: unset !important;
  }
}
