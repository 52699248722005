.policyList {
  // margin-top: 10rem;

  // hide focus overlays for mouse users
  // but show them for keyboard users.
  button {
    &:focus {
      outline: revert;

      &:not(:focus-visible) {
        outline: none;
      }
    }
    &:focus-visble {
      outline: revert;
    }
  }

  // section {
  //   padding: 2rem;
  //   border: 1px solid #eee;
  //   margin-bottom: 1rem;

  //   > div {
  //     display: flex;
  //     justify-content: space-between;
  //   }

  //   h1,
  //   h2 {
  //     font-size: 1.2rem;
  //     color: #1a305a;
  //   }

  //   h2 {
  //     font-size: 1rem;
  //     text-align: right;
  //   }

  //   a {
  //     margin-top: 0.5rem;
  //   }
  // }
}

.topLevelContainer {
  padding: 10px;
  margin-bottom: 7.5px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  background: #fff;

  .sectionAnchor {
    position: relative;
    // to account for the height
    // of the nav bar
    top: -135px;
  }

  .topLevelHeader {
    display: flex;
    align-items: center;
    padding: 5px;

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: #96d6db;
      margin-right: 1em;
    }

    h1 {
      font-family: "rawline";
      text-transform: none;
      color: #29334b;
      width: 100%;
      display: flex;
      align-items: baseline;

      font-style: normal;
      font-weight: 600;
      font-size: 20px;

      span {
        font-weight: 400;
        // font-size: 0.75em;
        font-size: 0.9em;
        margin-left: 0.5rem;
        margin-left: auto;

        strong {
          font-weight: 600;
        }
      }
    }
  }
  .categoryContainer {
    position: relative;
    margin-left: 25px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 5px;

    position: relative;
    padding-left: 36px;
    padding-top: 5px;
    padding-bottom: 10px;

    &:before {
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: -2.5px;
      width: 5px;
      border-radius: 2.5px;
      background-color: #96d6db;
      content: "";
    }
  }
}
