.styles {
  .toggle {
    color: #57bbff;
    text-decoration: underline;
    font-style: italic;
    font-size: 0.8em;
    cursor: pointer;
    white-space: nowrap;
  }
}
