@import "src/assets/styles/vars.module.scss";
.labeledIcon {
    display: flex;
    flex-flow: row;

    .iconContainer {
        display: grid;
        grid-auto-flow: column;
        &:not(:empty) {
            + .labelContainer {
                margin-left: 1rem;
            }
        }
    }

    .labelContainer {
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: $idea-black;
    }
}
