@import "../../../assets/styles/vars.module.scss";
@media #{$phone} {
  .navWrapper {
    z-index: 4;
    border-bottom: 1px solid #ccc;
    position: fixed;
    .nav {
      img {
        height: 35px;
      }
      .menu {
        .links {
          opacity: 0;
          transition: opacity 0.25s;
          z-index: 0;
          padding: 20px;
          padding-right: 60px;
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 8px;
          position: absolute;
          top: 10px;
          right: 5px;
          @include boxShadow();
          > a,
          > div {
            &:not(:first-child) {
              line-height: 2;
              position: relative;
              top: 5px;
              margin-left: 0;
            }
          }
          > a:last-child,
          > div:last-child {
            position: relative !important;
            background-color: transparent !important;
            padding: unset;
            line-height: 2;
            right: unset;
            top: 5px !important;
            font-size: unset !important;
            font-weight: unset !important;
            &.active {
              font-weight: bold !important;
            }
          }
        }
        // .navSubmenu {
        //   max-width: 50vw;
        // }
        .hamburger {
          color: #333;
          z-index: 10000;
          position: relative;
          top: 3px;
          background: none;
          border: none;
          cursor: pointer;
          display: unset;
          padding: 0;
          i {
            font-size: 2rem;
          }
        }
      }
    }
    &.showMobileMenu .nav .links {
      transition: opacity 0.25s;
      opacity: 1;
    }
    &:not(.showMobileMenu) {
      // pointer-events: none;
      .hamburger {
        pointer-events: all;
      }
    }
    &.wide {
      + div {
        height: calc(100% - 21px);
        top: 21px;
      }
    }
  }
}
