@import "../../../assets/styles/vars.module.scss";

[class*="type-light"] {
  background-color: none;
  max-width: 600px;
  font-size: 0.9rem;
  opacity: 1 !important;
  display: flex;
  flex-flow: column;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.29);
  padding: 10px !important;
  z-index: 2;
  p:not(:last-child) {
    margin-bottom: 20px;
  }
}
.wide {
  max-width: 600px !important;
  p:not(:last-child) {
    margin: 0;
  }
}
.infoTooltip {
  display: inline-flex;
  margin: 0;
  position: relative;
  pointer-events: all;
  align-items: center;

  img {
    cursor: default;
  }
}

@import "./plugins.module.scss";
