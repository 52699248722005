@import "../../../assets/styles/vars.module.scss";
.style {
  .content {
    .entry {
      .quantized {
        .rectGroup {
          &:nth-child(2) {
            .rect {
              &.labelsInside {
                border-color: #808080 !important;
              }
            }
          }
        }
      }
    }
  }
}
