.expanderButton {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.closer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}
