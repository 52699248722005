.miniPolicyBox {
  flex-basis: 32%;
  display: flex;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-right: 2%;
  &:last-of-type {
    margin-right: 0;
  }

  .description {
    h1 {
      font-size: 20px;
      color: #29334b;
      line-height: 1.4;
      font-weight: 600;
      text-transform: unset;
    }

    h2 {
      font-size: 1rem;
      color: #29334b;
      font-weight: 300;
    }

    h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #e55e37;
      margin-top: 0.5em;
      margin-bottom: 0.5rem;
      text-transform: none;
    }
  }
}
