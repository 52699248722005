.xCloseBtn {
    display: flex;
    visibility: hidden;

    cursor: pointer;
    pointer-events: none;

    border: none;

    background: none;
    background-color: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    $size: 0.75rem;
    height: $size;
    width: $size;

    margin: 0 0.5rem;

    &.show {
        visibility: visible;
        pointer-events: all;
    }
}
