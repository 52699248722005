.categoryHeader {
  position: relative;
  padding-top: 8px;
  padding-left: 0px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  margin-left: -8px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0);

  &:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 6px;
    height: 1px;
    width: 100%;

    background-color: #71919400;
    // rgba(0, 0, 0, 0);
    transition: 250ms ease;
  }

  &:hover {
    &:after {
      background-color: #7191947f;
      transition: 150ms ease;
      // background-color: rgba(0, 0, 0, 0.125);
    }
  }

  // &:hover {
  //   border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  // }

  h2 {
    font-family: "rawline";
    font-size: 22px;
    font-weight: 500;
    text-transform: none;
    color: #29334b;
    margin-left: 0.5rem;
    width: 100%;
    display: flex;
    align-items: baseline;

    span {
      font-weight: 400;
      // font-size: 0.75em;
      font-size: 0.9em;
      margin-left: 0.5rem;
      margin-left: auto;

      strong {
        font-weight: 600;
      }
    }
  }
}

.categoryContainer {
  position: relative;
  padding-left: 36px;
  padding-top: 5px;
  padding-bottom: 10px;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 5px;
    border-radius: 2.5px;
    background-color: #7fb0b4;
    content: "";
  }
}
