.breadCrumbs {
  margin-top: 1.3rem;
  display: flex;
  align-items: center;

  a {
    color: rgba(130, 130, 130, 1);
    font-size: 16px;
  }
}
