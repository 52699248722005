@import "../../../assets/styles/vars.module.scss";

.radioToggle {
  // max-width: 200px;
  select {
    max-width: 90%;
  }
  > div {
    font-weight: bold;
  }
  form:not(.asGrid) {
    display: flex;
    flex-flow: column;
    margin-left: 10px;
  }
  form.asGrid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0 10px;
  }

  // tooltip text displayed beneath form rather than as hoverable icon
  .footnote {
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.2rem;
  }

  span.hasChildren + div {
    // indent children
    form {
      @include indented;
      margin-left: 5px;
    }
    // space between children and next radio toggle, if present
    + span {
      margin-top: 10px;
    }
  }
  span:last-child label {
    margin-bottom: 0;
  }
  label {
    font-weight: normal;
    color: black;
    display: flex;
    flex-flow: row;
    align-items: baseline;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;

    &[disabled] {
      pointer-events: none;
      cursor: default !important;
      input,
      & {
        color: gray !important;
      }
    }
  }
  input {
    margin-right: 5px;
    cursor: pointer;
    &:checked + span {
      font-weight: 600;
    }
  }
  &.disabled {
    pointer-events: none;
    color: gray;
    input,
    label {
      color: gray;
    }
  }

  // Horizontal version
  &.horizontal {
    display: flex;
    flex-flow: row;
    max-width: unset;
    > div {
      display: flex;
      flex-flow: row;
      white-space: nowrap;
    }
    form {
      display: flex;
      flex-flow: row;
      label {
        padding: 0 10px;
        width: max-content;
      }
      > span:not(:last-child) > label {
        margin-right: 1.5em;
      }
    }

    &.right {
      margin-left: auto !important;
    }
    &.left {
      margin-right: auto !important;
    }
    &.labelPosTop {
      display: flex;
      flex-flow: column;
      justify-content: center;
      form {
        margin: 0;
        span:first-child > label {
          padding: 0;
        }
      }
    }
  }

  // inline label positioning
  &.labelPosInline {
    display: flex;
    align-items: center;
  }

  // themes
  &.slim {
    label {
      text-transform: uppercase;
      color: $idea-black;
      line-height: 1.125rem;
    }
  }
}
